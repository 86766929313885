import React from "react"
import { graphql } from "gatsby"
import Slices from "../components/Slices"
import ParseSEOData from "../components/ParseSEOData"

export const query = graphql`
  query PageQuery($uid: String, $lang: String) {
    prismic {
      allPages(uid: $uid, lang: $lang) {
        edges {
          node {
            title
            meta_description
            meta_title
            share_image
            _meta {
              type
              uid
              lang
            }
            body {
              ... on PRISMIC_PageBodyHero {
                type
                label
                primary {
                  heading
                  image
                  cta_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Samples {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Shop {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Collection {
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Case {
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                  cta_text
                }
              }
              ... on PRISMIC_PageBodyContact_form {
                type
                label
              }
              ... on PRISMIC_PageBodyReview {
                type
                label
                primary {
                  reviews_title
                }
                fields {
                  review {
                    ... on PRISMIC_Review {
                      title
                      author
                      date
                      review
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyCollections {
                type
                label
              }
              ... on PRISMIC_PageBodyIntro {
                type
                label
                primary {
                  intro_cta {
                    ... on PRISMIC_Page {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Samples {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Shop {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Collection {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                    ... on PRISMIC_Case {
                      title
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                  intro_text
                  intro_title
                }
                fields {
                  feature_description
                  feature_icon
                  feature_title
                  feature_link {
                    _linkType
                  }
                }
              }
              ... on PRISMIC_PageBodyArticle {
                type
                label
                fields {
                  rich_text
                }
              }
              ... on PRISMIC_PageBodyConsultancy {
                type
                label
                primary {
                  consultancy_text
                  consultancy_title
                  consultancy_heading
                  consultancy_image
                  contact_info
                }
                fields {
                  time_slot
                }
              }
              ... on PRISMIC_PageBodyFacetime_calendar {
                type
                primary {
                  title1
                  description
                }
              }
              ... on PRISMIC_PageBodyBook_shoowroom {
                type
                primary {
                  title1
                }
              }
              ... on PRISMIC_PageBodyBook_designm_de {
                type
                primary {
                  title1
                }
              }
              ... on PRISMIC_PageBodyShowroom {
                type
                primary {
                  title1
                  description
                }
              }
              ... on PRISMIC_PageBodyIframe {
                type
                label
                primary {
                  embed_description
                  embed_title
                  embed_url
                }
              }
              ... on PRISMIC_PageBodyFeature {
                type
                label
                primary {
                  feature_title
                  feature_text
                  feature_image
                  feature_heading
                  feature_imageSharp {
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                  feature_cta_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Inspiration {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Samples {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Shop {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Collection {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                    ... on PRISMIC_Case {
                      _meta {
                        uid
                        type
                        lang
                      }
                    }
                  }
                  feature_cta_text
                }
              }
            }
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {
  const doc = data.prismic.allPages.edges.slice(0, 1).pop()
  if (!doc) return null

  return (
    <>
      <ParseSEOData data={doc.node} />
      <Slices allSlices={doc.node.body} uid={doc.node._meta.uid} lang={doc.node._meta.lang} />
    </>
  )
}

Page.query = query

export default Page
